.container {
  text-align: left;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;

    h1 {
      margin: 0;
    }

    .actionButtons {
      margin-left: auto;
      display: flex;
      gap: 0.5rem;
    }
  }

  .solutionActions {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .alignRight {
      margin-left: auto;
      display: flex;
      gap: 1rem;
    }

    .haveGap {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .solutionStatusDropDown {
      min-width: 8rem;
    }

    .newProductButton {
      margin-right: 0.5rem;
    }

    .productFilter {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      gap: 1.25rem;

      .productTypeLabel {
        margin-right: 0.75rem;
      }

      .productTypeDropDown {
        min-width: 14rem;
      }
    }
  }

  .solutionsGrid {
    .searchInput {
      min-width: 24rem;
    }

    .card {
      cursor: pointer;
      margin: 0.5rem 1.5rem 1rem 0;
      width: 24rem;
      max-width: 24rem;
      min-height: 15rem;
    }

    .card:hover {
      border-color: var(--secondary-color);
    }

    .card:active {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    .filterValues {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem 0 1.5rem;

      span {
        background-color: #ededed;
        padding: 0.2rem 0.35rem;
        border-radius: 36px;
        font-size: small;

        .removeFilter {
          cursor: pointer;
        }
      }

      .clearAll {
        font-size: smaller;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }

    .solutionFamilyAndStatus {
      svg {
        path {
          fill: var(--primary-color) !important;
        }

        vertical-align: bottom;
        font-size: 1.5em;
        margin-right: 0.25rem;
      }

      .solutionFamily {
        font-size: smaller;
        color: var(--gray-600);
      }

      .solutionStatus {
        color: var(--gray-600);
        font-style: italic;
      }
    }

    .cardTitles {
      height: 3rem;

      .solutionTitle {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-weight: lighter;
      }

      .productVersion {
        // margin-left: 0.5rem;
        color: var(--gray-600);
        font-weight: lighter;
        font-style: italic;
      }
    }

    .cardContent {
      font-size: small;
      overflow: hidden;
      height: 5.5rem;
      margin-top: 1.5rem;

      .tagline {
        font-style: italic;
        font-size: smaller;
      }

      .teaser {
        font-size: small;
        color: var(--text-color-secondary);
      }
    }

    .solutionContent {
      height: 10rem;
    }

    .cardFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: smaller;

      .addonType {
        background-color: #ffde74;
        border-radius: 10rem;
        margin-bottom: 0.5rem;
      }

      .productVersion {
        color: var(--gray-600);
        font-weight: lighter;
        font-style: italic;
        margin-left: auto;
      }
    }

    .solutionsLength {
      margin-left: 1rem;
      font-weight: bolder;
      color: #495057;
    }
  }
}
