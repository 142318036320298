.DisplayCardsComponent{
.componentGrid {
  display: flex;
  flex-wrap: wrap;
  .component {
    margin: 0 1.5rem 1.5rem 0;

    .trash_button_position {
      float: right;
    }

    .card_selected {
      outline: 2px solid var(--primary-color);
    }

    .disabledCard {
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    }

    .disabledBlueCard {
      pointer-events: none;
      cursor: default;
      background-color: #b5e0f5;
    }    

    .enabledCards {
      cursor: pointer;
    }

    .card:hover {
      border-color: var(--secondary-color);
    }

    .card:active {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    .cardContent {
      font-size: 1rem;
      overflow: hidden;
      width: 16rem;
      height: 6rem;
      max-width: 16rem;
      max-height: 6rem;

      .productFamily {
        font-size: smaller;
        color: var(--gray-600);

        svg {
          font-size: 1.25em;
          color: var(--primary-color);
          vertical-align: text-bottom;
          margin-right: 0.25rem;
        }
      }

      .title {
        padding-top: 0.5rem;
        font-weight: 600;
      }

      .description {
        font-weight: lighter;
        font-size: small;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0.5rem;
      }

      .cardFooter{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        font-size: smaller;
        margin-top: auto;

        .duplicateElementLabel{
          background: #EDEDED;
          border-radius: 10rem;
          padding: 0.125rem 0.625rem;
        }

        .footer {
          color: var(--text-color-secondary);

          i {
            margin-left: 15px;
          }
        }
      }
    }
  }


}

.dropdown {
  width: 100%;
}

.searchField{
  margin: 1rem 0rem 1rem 0rem;
  .saerchBar{
    max-width: 48rem;
    width: 100%;
  }
   .inputField {
    width: 100%;
   }
}

.tooltipText {
  font-size: smaller;
  padding-left: 1rem;
  margin: 0;
}


}

.searchComponent{
  width: 100%;
}






