.ManageBpo {
  width: 35rem;

  .dialogContent {
    .searchBarAndButton {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-top: 0.5rem;
    }

    .subheader {
      font-size: smaller;
      color: #b2b2b2;
      margin: 0.5rem 0;
    }

    .existingUsers {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .user {
        padding: 0.75rem;
        border-radius: 4px;
        background-color: var(--gray-100);
        display: flex;
        align-items: center;

        .deleteIcon {
          margin-left: auto;
          color: var(--blue-600);
          cursor: pointer;
        }
      }
    }
  }
}

.user {
  .email {
    color: var(--primary-color);
    font-size: smaller;
    text-decoration: underline;
  }
}
