.RegionTree {}

.scopeTypeIcon {
  margin-right: 0.5rem;
  margin-top: 2px;
  display: flex;
  align-items: flex-start;

  .banIcon {
    color: #b80012;
  }

  .checkIcon {
    color: #40ab35;
  }

  .partialCheckIcon {
    color: #ffb302;
  }
}

.treeItemTemplate {
  display: flex;
  padding: 2px 0;
}
