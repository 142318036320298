.SpecificationList {
  .divider {
    width: 100%;
  }

  .actionButtons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.badgeStyle {
  .tagName {
    padding: 0 10px;
    color: #141414;
    font-weight: 400;
  }

  .coreServiceTag {
    background-color: #b5e0f5;
  }

  .optionalServiceTag {
    background-color: #ffeba8;
  }

  .noneServiceTag {
    background-color: #ededed;
  }
}

.specification {
  .specificationHeader {
    display: flex;
    margin-bottom: 1rem;

    .specNameAndIcon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    h4 {
      margin: 0;
    }

    .actionButtons {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .specificationInnerContent {
    display: grid;
    grid-template-columns: 1fr 10rem;
    column-gap: 1rem;

    .specificationDescriptionAndConfig {
      .specificationConfiguration {
        margin-top: 2.5rem;
      }
    }

    .configurable {
      border-radius: 4px;
      border: 1px solid #dbdbdb;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      .checkBoxOption {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      .configurableHeader {
        margin-bottom: 0.5rem;
      }

      .configurableOptions {
        margin-left: 1rem;
        font-size: smaller;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.dollarWithSpecName {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
}

.dollarLabelBox {
  background: #ededed;
  border-radius: 50%;
  display: flex;
}
