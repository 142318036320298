.FeatureVersionCompare {
  height: 70vh;
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  .compareBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .featureContent {
      border: 1px solid #cfcfcf;
      border-radius: 4px;
      font-size: smaller;
      padding: 1.5rem;

      .featureHeader {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .oldFeature {
    background-color: #f7f7f7;
  }
}
