.ProductOverview {
  display: grid;
  column-gap: 1.25rem;
  grid-template-columns: minmax(0, 1fr) 21.25rem;

  .field {
    margin-bottom: 1rem;
    min-height: 5rem;

    .fieldTitle {
      .label {
        margin-right: 0.5rem;
      }

      .modConStatus {
        background-color: #ededed;
        padding: 0.25rem 1rem;
        font-size: smaller;
        border-radius: 10rem;
      }

      .modConStatusActive {
        background-color: #c5e5c2;
      }
    }
  }

  .greyedButtonBackground {
    background-color: white;
    color: black;
  }

  .alignCenter{
    text-align: center;
  }
}
