.usedInSolutions {
  .solutionByStatus {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
  }
  .displaySolutionList {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #0073ab;
    margin-bottom: 1rem;

    .pointer {
      cursor: pointer;
    }
  }
}
