.productCardView {

	.productsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .productCountDropdown {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

	.productGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    .product {
      margin: 0 1.5rem 1.5rem 0;
      overflow: hidden;

      .statusLabel {
        position: relative;
        background-color: #ffde74;
        padding: 0 2rem;
        font-size: x-small;
        float: inline-end;
        transform: rotate(46deg);
        top: 20px;
        right: -2.25rem;
        overflow: hidden;
        line-height: 1rem;
      }

      .card {
        border: 0.5px solid var(--gray-400);
        border-radius: 4px;
        padding: 1rem;
        width: 22rem;
        height: 14.5rem;

        .selectProductType {
          display: flex;
          align-items: baseline;
          gap: 0.5rem;

          .relationType{
            width: 22.5rem;
            //height: 2rem;
            align-items: center;
          }
        
          .relationTypeViewTag {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        
            .relationTypeViewText {
              font-size: 0.875rem;
              font-weight: normal;
              padding: 0.25rem 0.625rem;
              border-radius: 10% / 50%;
            }
         
            .relationTypeViewTextCore {
              background-color: #b5e0f5;
            }
        
            .relationTypeViewTextOptional {
              background-color: #fff1c2;
            }
          }
        }
      }

      .retiredStatusLabel {
        position: relative;
        background-color: #b80012;
        padding: 0 2rem;
        font-size: x-small;
        float: inline-end;
        transform: rotate(46deg);
        top: 20px;
        right: -1.25rem;
        overflow: hidden;
        line-height: 1rem;
        color: #fff;
      }

      .upgradeCard {
        background-color: #fff1c2;
      }

      .card:hover {
        border-color: var(--secondary-color);
      }

      .card:active {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }

      .retiredCard {
        background-color: #f7f7f7;
      }

      .cardContent {
        cursor: pointer;
        //height: 80%;
        height: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .productFamily {
          font-size: smaller;
          color: var(--gray-600);

          svg {
            font-size: 1.25em;
            color: var(--primary-color);
            vertical-align: text-bottom;
            margin-right: 0.25rem;
          }
        }

        .textWithEllipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          display: box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .title {
          font-size: larger;
        }

        .description {
          font-size: small;
          color: var(--gray-600);
        }
      }

      .cardFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: smaller;
        margin-top: auto;
        margin-bottom: 0.5rem;

        .addonType {
          background-color: #ffde74;
          border-radius: 10rem;
          padding: 0.125rem 0.625rem;
        }

        .productVersionAndDeleteIcon {
          margin-left: auto;

          .productUpgrade{
            font-weight: normal;
            cursor: pointer;
            color: #1c80cf;
            text-decoration: underline;
            margin-left: 0.5rem;
          }

          .deleteIcon {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}

.customDropdownItem {
  max-width: 18.75rem;
 
  .optionName {
    color : #141414;
		font-size: 1rem;
  }
 
  .optionDescription {
    color: #767676;
		font-size: 0.75rem;
  }
}

.customDropdownItem span {
  display: block;
}
 
.customDropdownItem div {
  white-space: normal; 
}