@import "./colors";

h1,
h2 {
  font-family:
    "Maersk Headline",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

h1 {
  font-size: xxx-large;
  font-weight: 200;
  display: inline-block;
  line-height: 3rem;
}

h2 {
  font-size: x-large;
  font-weight: 400;
  display: inline-block;
}

h3 {
  font-size: medium;
  font-weight: 600;
  display: inline-block;
}

// h1 {
//   font-size: 56px;
//   line-height: 56px;
//   font-weight: 300;
// }

// h2 {
//   font-size: 38px;
//   line-height: 38px;
//   font-weight: 300;
// }

// h3 {
//   font-size: 26px;
//   line-height: 32px;
//   font-weight: 300;
// }

// h4 {
//   font-size: 22px;
//   line-height: 26px;
//   font-weight: 400;
// }

// h5 {
//   font-size: 20px;
//   line-height: 24px;
//   font-weight: 300;
// }

* > button {
  cursor: pointer;
}

.body-text-default {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

hr {
  border-color: var(--gray-200);
  border-style: solid;
  border-width: 1px 0 0;
}

body {
  overflow-y: hidden;
}

.edit_frame {
  // min-height: 93vh;
  border: 5px solid var(--primary-color);
}

.normal_frame {
  // min-height: 93vh;

  // border: 5px solid transparent;
}

.notch {
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: table;
  z-index: 40;
  left: 50%;
  transform: translate(0%, -10%);

  .p-button {
    border-radius: 0;
  }
}

.dialog_reject {
  float: right;
  background-color: transparent;
  color: #2196f3;
  border-color: transparent;
}

.sidebarCollapsed {
  display: inline-block;
  width: 4rem;
  z-index: 99999999;
}

.mainDisplayCard {
  padding: 2rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%);
}
