.SolutionFeatureAssociationsHeader {
  .filtersOverview {
    margin-top: 1rem;

    .pill {
      margin-left: 0.5rem;
    }
  }

  .customFilter {
    margin-top: 2rem;
    font-size: small;
    display: inline-block;
    vertical-align: top;

    .title {
      padding-bottom: 2rem;
    }
  }

  .componentName {
    font-size: x-large;
    font-family:
      "Maersk Text",
      -apple-system,
      BlinkMacSystemFont,
      "Microsoft JhengHei",
      "Microsoft Yahei",
      "微软雅黑",
      STXihei,
      "华文细黑",
      sans-serif;
  }

  .headerContainer {
    display: block;
    flex-wrap: wrap;
    align-items: baseline;

    .titleButtons {
      margin-left: auto;
      float: right;

      button {
        margin-right: 20px;
        border-color: rgb(207 207 207);
        color: rgb(53 53 53);
      }

      span {
        display: inline-block;
      }
    }

    .spanDropdown {
      display: inline-block;
      padding-right: 20px;
    }
  }
}

.componentItem {
  span {
    display: block;
  }
}

.dropDownFooter {
  padding-top: 0.5rem;
  font-size: smaller;
  font-style: italic;
  color: var(--text-color-secondary);
}

.allfeatures {
  font-weight: bold;
}

.componentDropdown {
  min-width: 30rem;
}

.activeFiltersAndServiceModeFilter {
  flex-grow: 1;
  display: flex;
  padding-top: 1rem;
  align-items: baseline;
}

.serviceModelFilter {
  margin-left: auto;
}
