// checkbox tree

.RegionTree {
  .p-tree {
    border: none;
    background: #fff;
    padding: 0;
    border-radius: 0;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    display: none;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    display: none;
  }

  // tree

  .p-tree::-webkit-scrollbar {
    display: none;
  }

  .p-tree .p-tree-container .p-treenode {
    padding-right: 0;
  }

  .p-tree-container {
    padding-right: 0.5rem;
  }

  .noDefaultTreeFilter {
    .p-tree .p-tree-filter-container {
      display: none;
    }
  }

  .noEdit {
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: none;
    }

    .p-treenode-selectable {
      cursor: default;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: 0 0 0 0;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    border-color: transparent;
    background: none;
  }

  .p-tree .p-treenode-children {
    padding: 0.143rem 0 0 1rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: 0 0 0 0;
  }
}
