.FeatureCard2 {
  border: 1px solid var(--gray-400);
  border-radius: 4px;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .versionCompare {
    cursor: pointer;
    color: #0073ab;
    text-decoration: underline;
  }

  .leftAndRightParts {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .header {
    display: flex;

    .headerTitleAndVersion {
      display: flex;
      gap: 1rem;

      //align-items: center;
      align-items: flex-start;
      flex-direction: row;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      .featureNameAndVersion {
        display: flex;
        gap: 0.75rem;

        //flex-direction: row;
        align-items: center;
      }

      h2 {
        margin: 0;
        color: var(--gray-900);
      }

      .featureVersion {
        font-size: 0.875rem;
        line-height: 1.063rem;
        padding-left: 0.5rem;
      }

      i {
        cursor: pointer;
      }

      .featureVersionAvailable {
        background-color: #ffeba8;
        border-radius: 10% / 50%;
        font-size: 0.875rem;
        padding: 0.125rem 0.625rem;
      }

      .actionButton {
        margin-left: auto;
      }
    }

    .dollarAndDeleteButton {
      display: flex;
      margin-left: auto;
      gap: 0.5rem;
    }

    .dollarLabelBox {
      background: #ededed;
      border-radius: 50%;
      display: flex;
    }
  }

  .featureCardLeftpart {
    flex-grow: 1;

    .featureVersionAvailable {
      background-color: #ffeba8;
      border-radius: 10% / 50%;
      font-size: 0.875rem;
      padding: 0.125rem 0.625rem;
      align-self: flex-start;
    }

    .descriptionAndUsedIn {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 1rem;
      overflow-wrap: anywhere;
    }

    .featureVersion {
      font-size: 0.875rem;

      //line-height: 1.063rem;
      //padding-left: 0.5rem;
    }
  }

  .featureCardRightpart {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    text-align: center;

    .serviceDefaultRadioButtonGroup {
      flex-direction: row;
    }

    .labelBox {
      padding: 2px 10px;
      border-radius: 100px;

      .label {
        padding: 2px 10px;
        min-width: 9rem;
      }
    }

    .labelBoxCore {
      background: #b5e0f5;
    }

    .labelBoxOptional {
      background: #ffeba8;
    }

    .labelBoxNonService {
      background: #ededed;
    }

    .featureTypeValueLabel {
      border-radius: 6.25rem;
      padding: 0.123rem 0.625rem;
      background: #ededed;
    }
  }

  .descriptionAndUsedIn {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
  }

  .featureDescription {
    color: var(--gray-700);
  }
}

.previewBtn {
  background: #fff;
  color: #141414;
  border: 1px solid #cfcfcf;
}

.upgradeBtn {
  background: #00243d;
  color: var(--primary-color-text);
  border: none;
}

.optionalityChangeWarningMsg {
  color: red;
  margin-bottom: 0.9rem;
  font-size: 0.85rem;
}

.optionalityChangeInfoMsg {
  margin-bottom: 0.9rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.optionalityChangeMsgInfoIcon {
  color: #099cd9;
}

.serviceDefaultAndDollar {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .alignLast {
    align-self: flex-end;
  }
}

.flexDisplay {
  display: flex;
}
