.DisplayBigCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .addedHighlight {
    outline: 2px solid green !important;
  }

  .deletedHighlight {
    outline: 2px solid red !important;
    cursor: default !important;
  }

  .disabledCard {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
  }

  .disabledView {
    opacity: 0.5;
    cursor: default;
  }

  .componentCard {
    outline: 1px solid var(--gray-400);
    border-radius: 4px;
    padding: 1.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:hover {
      outline: 2px solid var(--primary-color);
    }

    .title {
      font-weight: 400;
      font-size: 1.25rem;
    }

    .description {
      font-weight: lighter;
      font-size: smaller;
      color: var(--gray-700);
      overflow: hidden;
    }

    .featuresTooltip {
      display: flex;
      align-items: center;
      font-size: smaller;
      color: var(--gray-700);

      i {
        margin-left: 0.5rem;
      }
    }

    .botomElements {
      display: flex;
      gap: 1rem;
      margin-top: auto;

      .featureUpgradeLabel {
        display: flex;
        background-color: #FFEBA8;
        flex-direction: row;
        align-items: center;
        border-radius: 36px;
        padding: 2px 10px 2px 10px;
        font-size: smaller;
      }

      .actionButtons {
        button {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.tooltipText {
  font-size: smaller;
  padding-left: 1rem;
  margin: 0;
}
