.RuleComponentReadView {
	.ruleSection {
    margin-top: 1rem;

    .ruleName {
      display: flex;
      font-weight: bold;

      .invalidRuleHighlight {
        color: #7e570f;
      }

      svg {
        path {
          fill: #946a26 !important;
        }
      }

      .invalidRuleIcon {
        margin-right: 0.5rem;
      }
    }

    .ruleCriteria {
      .italic {
        font-style: italic;
      }

      .criteriaValue {
        color: #326ba8;
      }

      .criteriaValueStrikedOff {
        color: #326ba8;
        text-decoration: line-through;
      }
    }
  }
}