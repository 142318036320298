.InputRichTextWithSave {
  width: 100% !important;
  .InputText {
    width: 100%;

    .Input {
      width: 100%;
    }
  }

  .InputActionButtons {
    display: flex;
    margin-top: 0.3rem;
    justify-content: space-between;

    button {
      width: 1.7rem !important;
      height: 1.7rem !important;
      margin-top: 0 !important;
      margin-left: 0.5rem !important;
      //margin-left: 0 !important;
    }
  }
}
