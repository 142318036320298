.SideBarNavigation {
  background: #00243d;
  min-height: 100vh;
  width: 300px;
  transition: transform 500ms ease-in-out;
  transform: translateX(calc(-100% + 4rem));

  @mixin highlight {
    .menuItem {
      background: #43b0d5 !important;
      border-radius: 10px;
    }
  }

  @mixin highlighthover {
    .menuItem {
      background: #193a50;
      border-radius: 10px;
    }
  }

  .sideBarContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    height: 100vh;
    width: 100%;
    padding: 0 0.5rem;

    i {
      color: white;
    }

    .sideBarHeader {
      display: flex;
      align-items: center;
      height: 65px;
      width: 100%;
      justify-content: center;

      .sideBarLogoText {
        flex-grow: 0;
      }

      .sideBarLogoIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        width: 4rem;
        flex-shrink: 0;
      }
    }

    .menuItem {
      display: flex;
      align-items: center;
      height: 3rem;
      width: 100%;
      cursor: pointer;

      .menuItemText {
        display: flex;
        align-items: center;
        color: white;
        font-size: medium;
        height: 4rem;
      }

      .menuItemLogo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: xx-small;
        height: 3rem;
        width: 3.5rem;

        .iconText {
          margin-top: 0.1rem;
          text-align: center;
        }
      }
    }

    .menuOpenArrow {
      margin-top: auto;
    }

    .menuCloseArrow {
      transform: rotate(180deg);
    }
  }

  .sideBarCollapsed {
    padding: 0 !important;

    .activeSelectionMask {
      width: 100%;

      &:hover {
        .menuItem {
          .menuItemLogo {
            background: #193a50;
            border-radius: 10px;
          }
        }
      }

      .menuItem {
        justify-content: flex-end;
        padding-right: 0.25rem;
      }
    }

    .activeSelection {
      .menuItem {
        .menuItemLogo {
          background: #43b0d5 !important;
          border-radius: 10px;
        }
      }
    }

    .menuItem {
      justify-content: flex-end;
    }

    .sideBarHeader {
      justify-content: flex-end;
    }
  }

  .sideBarOpen {
    .activeSelectionMask {
      width: 100%;

      &:hover {
        @include highlighthover;
      }
    }

    .activeSelection {
      @include highlight;
    }
  }
}

.isOpen {
  transform: translateX(0);
}
