.RegionSelection {
  display: flex;
  flex-wrap: wrap;

  .regionName {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 0.5px solid var(--primary-color);
    border-radius: 4px;
    line-height: 1.25rem;

    // height: 4rem;
    padding: 0.5rem 1.25rem;
    cursor: pointer;

    .name {
      font-weight: bold;
      color: var(--primary-color);
    }

    .status {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: smaller;

      .banIcon {
        color: #b80012;
      }

      .checkIcon {
        color: #40ab35;
      }

      .partialCheckIcon {
        color: #ffb302;
      }
    }
  }

  .selectedRegion {
    background: var(--primary-color);

    .name {
      color: white !important;
    }

    .status {
      color: white !important;

      .banIcon {
        color: white !important;
      }

      .checkIcon {
        color: white !important;
      }

      .partialCheckIcon {
        color: white !important;
      }
    }
  }
}
