body {
  background: #f9fcff;

  // background: var(--surface-50);
  margin: 0;
  font-family:
    "Maersk Text",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.isChanged {
  // color: var(--primary-color);
  // font-weight: bold;
}
