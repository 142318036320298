.featureAccordion {
  margin-left: 1rem;
  .featureView {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // To cover side panel clickable area
    padding: 1rem 0.5rem;
    margin: 0rem -1rem -1rem 0rem;

    .featureIconName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 0.5rem;

      .featureName {
        font-size: medium;
        font-weight: bold;
        cursor: pointer;
      }

      .moreInfo {
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        color: #0073AB;
        text-decoration: underline;
        margin-left: 0.5rem;
      }

      .featureVersionAvailable {
        background-color: #ffeba8;
        border-radius: 10% / 50%;
        padding: 0.25rem 0.5rem;
        font-size: small;
        font-weight: normal;
      }

      .previewLink {
        font-size: small;
        font-weight: normal;
        cursor: pointer;
        color: #1c80cf;
      }
    }

    .featureActionButtons {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .removeFeatureBtn{
        color: #495057;
      }

      .featureTag {
        font-size: 0.875rem;
        font-weight: normal;
        padding: 0.25rem 0.625rem;
        border-radius: 10% / 50%;
      }

      .coreFeatureTag {
        background-color: #b5e0f5;
      }

      .optionalFeatureTag {
        background-color: #ffeba8;
      }
    }
  }

  .specificationParameterView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem 0 4rem;
    cursor: pointer;
    padding: 0rem;
    margin-left: 2.5rem;

    .specificationParameterIconName {
      display: flex;
      align-items: center;
      column-gap: 1.5rem;
    }

    .spOptionalityTagActionButtons {
      display: flex;
      align-items: center;
      gap: 1rem;

      .removeSPBtn {
        color: #495057;
      }

      .specParamSettingIcon,
      .specParamRemoveIcon {
        cursor: pointer;
      }
    }
  }

  .spTag {
    font-size: 0.875rem;
    padding: 0 0.625rem;
    border-radius: 20% / 50%;
  }

  .coreSPTag {
    background-color: #b5e0f5;
  }

  .optionalSPTag {
    background-color: #ffeba8;
  }
}

.enabled {
  // color: #1c80cf;
  cursor: pointer;
}

.disabled {
  color: #777;
}
