.FeatureSpecificationComponent {
  .specValueItem {
    padding-bottom: 1rem;

    .itemNameWithSwitches{
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .defaultSwitch{
        display: flex;
        text-align: center;
        gap: 0.5rem;
      }
    }
  }

  .specValueDescription {
    font-size: smaller;
    padding-left: 2.25rem;
    padding-top: 0.5rem;
  }

  .isChanged {
    color: var(--primary-color);
    font-weight: bold;
  }

  .isMissing {
    color: var(--gray-600);
    font-style: italic;
  }

  .Check,
  .Cross {
    margin-right: 0.7rem;
  }

  .label {
    padding: 0.25rem 0.6rem;
    border-radius: 10rem;
    font-size: smaller;
  }

  .grayLabel {
    background: #ededed !important;
  }
}
