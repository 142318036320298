.componentAccordion {
  margin-left: 0.5rem;

  .componentHeaderView {
    display: flex;
  }

  .componentView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0.5rem;

    .descriptionAlignment {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .componentDescription {
        font-size: 14px;
        color: #545454;
        font-weight: 400;
        margin-left: 1.8rem;
      }

      .componentIconName {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .componentName {
          font-size: 1.125rem;
          font-weight: 100;
        }
      }
    }

    .componentActionButtons {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: normal;

      .componentAddFeature {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .enabled {
        cursor: pointer;
      }

      .disabled {
        color: #777777;
      }
    }
  }
}
