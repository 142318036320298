
.productAccordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .productNameSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top:0.5rem;

    .productNameAndUpgrade {
      display: flex;
      align-items: normal;
      gap: 0.2rem;
      flex-direction: column;

      .productName {
        font-size: larger;
        margin-right: 0.5rem;
      }

      .featureVersionAvailable {
        background-color: #ffeba8;
        border-radius: 10% / 50%;
        padding: 0.25rem 0.5rem;
        font-size: small;
        font-weight: normal;
      }

      .productUpgrade {
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        color: #0073AB;
        text-decoration: underline;
        margin-left: 0.5rem;
      }

      .productDescription {
        padding-top: 0.5rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        .link{
          color: #0073AB;
          margin-left: 0.5rem;
        }
      }
    }

    .deleteProductIcon {
      cursor: pointer;
      background-color: #ffffff;
      border: 1px solid #ced4da;
      padding: 0.6rem;
    }

    .selectProductType {
      display: flex;
      align-items: baseline;
      gap: 0.5rem;
    }
  }

  .productCard {
    background-color: #ffffff;
  }

  .relationType {
    height: 2.5rem;
    width: 22.5rem;
    padding: 0.2rem;
  }

  .relationTypeViewTag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    background-color: transparent !important;
    box-shadow: none;

    .relationTypeViewText {
      font-size: 0.875rem;
      font-weight: normal;
      padding: 0.25rem 0.625rem;
      border-radius: 10% / 50%;
      color: #141414;
    }

    .relationTypeViewTextCore {
      background-color: #b5e0f5;
    }

    .relationTypeViewTextOptional {
      background-color: #ffeba8;
    }
  }
}

.customDropdownItem {
  max-width: 18.75rem;

  .optionName {
    color: #141414;
    font-size: 1rem;
  }

  .optionDescription {
    color: #767676;
    font-size: 0.75rem;
  }
}

.customDropdownItem span {
  display: block;
}

.customDropdownItem div {
  white-space: normal;
}

.productTypeError {
  display: flex;
  gap: 0.4rem;
  margin-top: 0.3rem;

  .errorIcon {
    color:#B80012; 
  }
}

.errorMessage {
  color: #B80012;
  font-size: 14px;
  margin-top: 0.2rem;
  font-weight: 400;
}

