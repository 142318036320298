.AddOnRules {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #f7f7f7;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 1rem;

    .headerTitle {
      font-size: large;
    }

    .ruleTitleTag {
      border-radius: 20rem;
      padding: 0 0.5rem;
      font-size: smaller;
    }

    .optOut {
      background-color: #d3ecf9;
      color: #00243d;
    }

    .optIn {
      background-color: #ededed;
    }

    .mandatory {
      background-color: #fcdde0;
      color: #370005;
    }

    .allRulesFilter{
      display: flex;
      align-items: center;
      gap: 0.25rem;
      color: #1c80cf;
      cursor: pointer;
      font-size: smaller;
    }

    .collapsibleArrow {
      margin-left: auto;
      cursor: pointer;
    }

    .rotateArrow {
      rotate: 180deg;
      transition: 0.5s;
    }
  }
}
