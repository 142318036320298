.ProductCardMinimal {
  border: 0.5px solid var(--gray-400);
  border-radius: 4px;
  padding: 1rem;
  width: 18rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    border: 0.5px solid black;
  }

  .productFamily {
    font-size: smaller;
    color: var(--gray-600);

    svg {
      font-size: 1.25em;
      color: var(--primary-color);
      vertical-align: text-bottom;
      margin-right: 0.25rem;
    }
  }

  .title {
    font-weight: 600;
  }

  .description {
    font-weight: lighter;
    font-size: small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0.5rem;
  }
}

.selected {
  outline: 2px solid var(--primary-color) !important;
  border: 0.5px solid transparent !important;
}
