.approversDialog {

}

.dialogContent {
	width: 35rem;
  height: 45rem;

	.subHeader{
		padding-bottom: 0.25rem;
	}

	.manageApproversLink {
		text-decoration: underline;
		cursor: pointer;
		margin-bottom: 1.125rem;
		color: #42B0D5;
	}

	.approverList {
		display: flex;
		gap: 2px;
		flex-direction: column;
	}

	.approversInfo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #F7F7F7;
		padding: 0.625rem;
		border-radius: 0.25rem;

		.approverNameEmail {

			.approverEmail{
				color: #42B0D5;
			}
		}

		.approverDesignation {
			color : #B2B2B2
		}
	}

	.defaultApproversInfo {
		background-color: #EDEDED;
	}
}