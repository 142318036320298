.RuleComponent {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;

  .ruleCriteria {
    border-left: 1px solid #dbdbdb;
    padding-left: 1rem;
    margin-top: 1rem;

    .addCriteriaBtn {
      margin-top: 1rem;
      padding-bottom: 0.5rem;
    }

  }

  .ruleActionBtn{
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
  }
}

.editAndDeleteAction {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}
