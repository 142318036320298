.Breadcrumbs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  .activeBreadCrumbLabel {
    color: var(--blue-600);
    cursor: pointer;
  }

  .disabledBreadCrumbLabel {
    color: var(--gray-700);
  }
}
