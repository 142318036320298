.ProductCompatibilities {

  .ruleWarningSection{
    align-items: center;
    border-radius: 4px;
    background-color: #fff3bf;
    color: #523a10;
    margin-bottom: 1rem;
    padding: 1rem;

    .ruleWarning {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .invalidIcon{
        padding-top: 0.5rem;
  
        svg {
          path {
            fill: #78510d !important;
          }
        }
      }
    }

    ul {
      margin-block-start: 0;
      margin-block-end: 0;
    }

  }

  .header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  .main {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .ribbonBar {
    display: flex;
    background-color: #fcdde0;
    border-radius: 0.25rem;
    padding: 0.5rem;
    flex-direction: column;

    .ribbonInfo {
      display: flex;
      align-items: center;

      .ribbonInfoIcon {
        display: flex;
        stroke: #b80012;
      }

      .link {
        text-decoration: underline;
        color: #00243d;
        cursor: pointer;
      }
    }

    .incompatibleProducts {
      padding-left: 3rem;

      .listInCompatibleProducts {
        display: flex;
        align-items: center;

        .incompatibleProductlistItem {
          font-size: 1.5rem;
          margin-right: 0.8rem;
          margin-bottom: 0.6rem;
        }
      }
    }

    .ribbonCloseIcon {
      padding: 1.4rem 1.25rem;
      cursor: pointer;
    }
  }
}
