.EditDimension {

}

.regionList {
  margin-left: 2rem;
}

.editDimensionItems {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;

  .banIcon {
    color: #b80012;
  }

  .checkIcon {
    color: #40ab35;
  }
}
