.ApplicabilitiesView {
  .scopeCountAddScopeButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .scopeCards {
    margin-bottom: 1.25rem;
  }
}
