.SpecificationParameterOptionality {
	.spTypeInnerRadioGroupRadioButtons {
		display: flex;
		align-items: center;
		gap: 2.5rem;
	
		.spTypeRadioButton {
			display: flex;
			gap: 0.5rem;
	
			.spTypeRadioButtonLabel {
				color: #141414;
			}
		}
	}
	
	.badgeStyle {
		margin-bottom: 20px;
	
		.standardOptionalityTag {
			height: 27px;
			vertical-align: middle;
			background-color: #ededed;
		}
	
		.optionalOptionalityTag {
			height: 27px;
			vertical-align: middle;
			background-color: #ffeba8;
		}
	
		.tagName {
			padding: 0 10px;
			color: #141414;
			font-weight: 400;
		}
	}
	
	.warning {
		width: 100%;
	}
}