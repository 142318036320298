.SolutionProductAssociation {
  .editSection {
    margin-left: auto;
    color: var(--blue-800);
    cursor: pointer;
    text-decoration: underline;
  }

  .productsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .productCountDropdown {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  .sharedComponent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .blockComponent {
      display: flex;
      gap: 0.6rem;
      justify-content: space-between;
      border: 0.5px solid var(--gray-400);
      min-height: 3.5rem;
      align-items: center;
      padding: 0 1.25rem;
      border-radius: 0.25rem;

      .productList {
        display: flex;

        .products {
          color: #0073ab;
          font-size: 0.9rem;
          padding-left: 0.5rem;
        }
      }
    }
  }

  .noSharedComponent {
    margin: 0 0 1rem 1rem;
  }

  .productGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    .product {
      margin: 0 1.5rem 1.5rem 0;
      overflow: hidden;

      .statusLabel {
        position: relative;
        background-color: #ffde74;
        padding: 0 2rem;
        font-size: x-small;
        float: inline-end;
        transform: rotate(46deg);
        top: 20px;
        right: -2.25rem;
        overflow: hidden;
        line-height: 1rem;
      }

      .card {
        border: 0.5px solid var(--gray-400);
        border-radius: 4px;
        padding: 1rem;
        width: 22rem;
        height: 14.5rem;
      }

      .retiredStatusLabel {
        position: relative;
        background-color: #b80012;
        padding: 0 2rem;
        font-size: x-small;
        float: inline-end;
        transform: rotate(46deg);
        top: 20px;
        right: -1.25rem;
        overflow: hidden;
        line-height: 1rem;
        color: #fff;
      }

      .upgradeCard {
        background-color: #fff1c2;
      }

      .card:hover {
        border-color: var(--secondary-color);
      }

      .card:active {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }

      .retiredCard {
        background-color: #f7f7f7;
      }

      .cardContent {
        cursor: pointer;
        height: 80%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .productFamily {
          font-size: smaller;
          color: var(--gray-600);

          svg {
            font-size: 1.25em;
            color: var(--primary-color);
            vertical-align: text-bottom;
            margin-right: 0.25rem;
          }
        }

        .textWithEllipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          display: box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .title {
          font-size: larger;
        }

        .description {
          font-size: small;
          color: var(--gray-600);
        }
      }

      .cardFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: smaller;
        margin-top: auto;

        .addonType {
          background-color: #ffde74;
          border-radius: 10rem;
          padding: 0.125rem 0.625rem;
        }

        .productVersionAndDeleteIcon {
          margin-left: auto;

          .deleteIcon {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .card:hover {
    border-color: var(--secondary-color);
  }

  .card:active {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}

.previewBtn {
  background: #fff;
  color: #141414;
  border: 1px solid #cfcfcf;
}

.upgradeOrCloseBtn {
  background: #00243d;
  color: var(--primary-color-text);
  border: none;
}
