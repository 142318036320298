.DefaultDimensions {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 90vh;
  width: 57rem;

  .commodityImage {
    width: 2.5rem;
    height: auto;
    padding: 0.75rem;

    svg {
      height: 15px !important;
      width: 15px !important;
    }
  }

  .searchBar {
    width: 100%;
  }

  .cardGrid {
    flex-wrap: wrap;
    display: flex;
    gap: 1.5rem;

    .dimensionCard {
      display: flex;
      flex-direction: column;
      width: 18rem;
      height: 11rem;
      border: 1px solid #dbdbdb;
      border-radius: 4px;

      .headerAndDescription {
        padding: 1rem;

        .header {
          display: flex;
          align-items: center;

          .imageType2 {
            width: 2.5rem;
            height: auto;
            text-align: center;
          }

          h3 {
            margin: 0;
          }

          .actionButton {
            margin-left: auto;

            .inputSwitchSelectedDisabled {
              height: 1.5rem;

              span {
                background: white;
                border: 1px solid #dbdbdb;
              }

              ::before {
                background: #dbdbdb;
              }
            }

            .inputSwitchDisabled {
              height: 1.5rem;

              span {
                background: #f7f7f7;
                border: 1px solid #dbdbdb;
              }

              ::before {
                background: #dbdbdb;
              }
            }

            .inputSwitch {
              height: 1.5rem;
            }
          }
        }

        .description {
          margin-top: 1rem;
          color: #878787;
          font-size: 12px;
          line-height: 1.5;
        }
      }

      .examples {
        margin-top: auto;
        border-top: 1px solid #dbdbdb;
        padding: 0.6rem 1rem;
        font-size: 10px;
        font-weight: 700;
      }
    }

    .disableDimensionThumbnail {
      background-color: #ededed;
    }

    .selectedDimensionThumbnail {
      background-color: #b5e0f5;
      opacity: 0.65;

      .headerAndDescription {
        color: black;

        .description {
          color: black;
        }
      }

      .examples {
        color: black;
      }
    }
  }
}
