.container {
  text-align: left;

  .featuredProducts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    h1 {
      margin: 0;
    }
  }

  .productsActions {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    .statusesToggleLabel {
      padding-left: 2rem;
      padding-right: 0.5rem;
    }

    .statusesToggle {
      vertical-align: middle;
    }

    .newProductButton {
      margin-right: 0.5rem;
    }

    .productFilter {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      .boldAndGreyed {
        font-weight: bolder;
        color: #495057;
      }

      .productStatusDropDown {
        min-width: 8rem;
      }

      .productTypeDropDown {
        min-width: 14rem;
      }
    }
  }

  .productsGrid {
    .card {
      cursor: pointer;
      padding: 1rem;
      margin: 0.5rem 1.5rem 1rem 0;
      width: 24rem;
      height: 15rem;
      border: 0.5px solid var(--gray-400);
      border-radius: 4px;
    }

    .card:hover {
      border-color: var(--secondary-color);
    }

    .card:active {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    .filterValues {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem 0 1.5rem;

      span {
        background-color: #ededed;
        padding: 0.2rem 0.35rem;
        border-radius: 36px;
        font-size: small;

        .removeFilter {
          cursor: pointer;
        }
      }

      .clearAll {
        font-size: smaller;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }

    .familyAndStatus {
      svg {
        font-size: 1.5em;
        color: var(--primary-color);
        vertical-align: text-bottom;
        margin-right: 0.25rem;
      }

      .productFamily {
        font-size: smaller;
        color: var(--gray-600);
      }

      .productStatus {
        color: var(--gray-600);
        font-style: italic;
      }
    }

    .productContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .cardTitles {
        h2 {
          margin-block-start: 0;
          margin-block-end: 0;
        }

        .productTitle {
          font-weight: lighter;
        }

        .productVersion {
          color: var(--gray-600);
          font-weight: lighter;
          font-style: italic;
        }
      }

      .cardContent {
        font-size: 1rem;

        .tagline {
          font-style: italic;
          font-size: smaller;
        }

        .teaser {
          font-size: smaller;
          color: var(--text-color-secondary);
        }
      }

      .cardFooter {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.875rem;

        .addonType {
          background-color: #ffde74;
          border-radius: 20% / 50%;
          padding: 0.125rem 0.625rem;
          margin-bottom: 0.5rem;
        }

        .productVersion {
          color: var(--gray-600);
          font-weight: lighter;
          font-style: italic;
          margin-left: auto;
        }
      }
    }

    .productLength {
      margin-left: 1rem;
      font-weight: bolder;
      color: #495057;
    }
  }
}

.p-component {
  font-family:
    "Maersk Text",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
}

.createButon {
  display: flex;
  float: right;
}

.test {
  font-weight: 300 !important;
}

.productListItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  .productListDetail {
    flex: 1 1 0;

    .productName {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
