.attributesList {
  .description {
    display: inline-block;
    font-size: medium;
    text-align: left;
    width: 100%;

    .p-col-8 {
      padding: 0 !important;
    }
  }

  .editOverview {
    float: right;
  }

  .attributes {
    margin-top: 1%;

    .attribute {
      margin-bottom: 1rem;

      .attributeHeader {
        i {
          color: var(--primary-color);
          vertical-align: middle;
          padding-right: 1rem;
          font-size: 2rem;
        }
      }

      .attributeText {
        margin-left: 3rem;
      }

      .attributeRadioButton {
        margin-left: 3rem;

        span {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.editFooter {
  padding-top: 1rem;
}

.dialog {
  width: 50vw;
}

@mixin display_fields {
  //width: inherit;
  width: 100%;
  height: inherit;
  opacity: initial !important;
}

.view_mode {
  @include display_fields;

  border: 0;
  padding-left: 0;
}

.edit_mode {
  @include display_fields;
}

.editor {
  margin: 1rem 0;
}

.displayDescription {
  white-space: pre-line;
  margin: 1rem 0;
}
