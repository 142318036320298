.SolutionFilter {
  .header {
    margin: 1.25rem 0;
    width: 100%;
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
    }

    h3 {
      margin: 1.25rem 0;
    }

    .closeIcon {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .subHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      cursor: pointer;
    }
  }

  .filterSection {

    .filterActions {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;

      .filterAction {
        color: var(--primary-color);
        font-size: smaller;
        cursor: pointer;
      }
    }

    .checkList {
      margin: 1rem 0;

      .checkbox {
        margin-right: 0.5rem;
      }
    }

    .productSearchMultiselect{
      width: 100%;
    }
  }

  .hidden {
    display: none;
  }
}
