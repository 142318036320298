.app-logo {
  height: 40px;
  width: 40px;
}

.app-header {
  background: white;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 3.5rem;
  padding-left: 3rem;

  // padding: 20px 80px;
  border-bottom: 1px solid #ededed;
  box-shadow: rgb(149 157 165 / 10%) 0 8px 24px;

  .userInfo {
    margin-left: auto;
    margin-right: 2rem;
    min-width: 40rem;
    display: flex;
    justify-content: flex-end;

    .userName {
      display: flex;
      align-self: center;
      font-size: small;
    }
  }

  .app-title {
    font-size: medium;
    font-weight: 700;
    margin-right: 0.5rem;
  }

  .app-subtitle {
    font-size: 1rem;
  }

  .header-left {
    span {
      margin-left: 1rem;
      margin-bottom: -6px;
      padding-right: 0.125rem;
      padding-left: 0.125rem;
      padding-bottom: 0.25rem;
    }

    .selectedMenu {
      border-bottom: var(--primary-color) solid 2px;
    }

    span:hover {
      cursor: pointer;
      border-bottom: var(--gray-600) solid 2px;
    }
  }
}

.app-content {
  padding: 1.25rem 3rem;
  min-height: 100%;
  line-height: 24px;
  width: calc(100vw - 4rem);
}

.appBody {
  display: inline-block;
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;

  .appDisplayArea {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "content";
  }
}

.app {
  max-width: 100vw;
  max-height: 100vh;

  // min-height: 100vh;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "nav content";
  overflow-y: hidden;
}
