.SolutionDetails {
  .ribbons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.75rem;

    .link {
      text-decoration: underline;
      color: #00243d;
      cursor: pointer;
    }

    .relationType {
      font-size: 14px;
    }

    .missingProductNames {
      font-size: 16px;
      margin-left: 0.25rem;
    }

    .relationTypeColor {
      display: flex;
      background-color: #FCDDE0;
      padding: 1rem 1rem 1rem 0.5rem;
      border-radius: 4px;

      .ribbonInfoIcon{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        stroke : #B80012;
        stroke-width: 0.5px;
      }
    }
  }

  .solutionHeader {
    text-align: left;
    margin: 1.5rem 0;
    position: relative;

    .headerRow {
      display: flex;
      flex-wrap: wrap;

      .versionSelect {
        border: none;
        margin-bottom: 1rem;
        background: #f9fcff;
      }

      .headerSection {
        display: flex;
        align-items: center;
        gap: 1rem;

        .solutionName {
          text-align: left;
          margin: 1.5rem 0;
          font-size: 1.5rem;
        }

        .cursorPointer {
          cursor: pointer;
        }
      }

      .addonType {
        height: fit-content;
        background-color: #ffde74;
        border-radius: 10rem;
        font-size: 0.875rem;
        padding: 0.125rem 0.625rem;
      }

      .actionButtons {
        margin-left: auto;
        align-self: center;
        display: flex;

        button {
          margin: 0.5rem 0.5rem 0.5rem 0;
        }
      }

      .editButton {
        margin-left: auto;
        align-self: center;

        button {
          margin: 0.5rem 0.5rem 0.5rem 0;
        }

        .approvalButton {
          background-color: #00243d;
          color: #fff;
        }
      }

      @media screen and (max-width: 1210px) {
        .actionButtons { margin-left: 0; }
      }
    }
  }

  .mainCardPosition {
    position: relative;

    .featureUpgradeBadge {
      position: relative;

      a::after {
        content: "";
        position: absolute;
        top: 9px;
        right: 7px;
        height: 12px;
        width: 12px;
        min-width: auto;
        background-color: #ffc319;
        border-radius: 50%;
      }
    }
  }

  .ribbonBar {
    display: flex;
    justify-content: space-between;
    background-color: #ffeba8;
    border-radius: 0.25rem;
    margin-bottom: 0.75rem;

    .ribbonInfo {
      display: flex;
      align-items: center;

      .ribbonInfoIcon {
        padding: 1rem 1.25rem;
      }

      .link {
        text-decoration: underline;
        color: #00243d;
        cursor: pointer;
      }
    }

    .ribbonCloseIcon {
      padding: 1.4rem 1.25rem;
      cursor: pointer;
    }
  }

  .solutionDraftRejectionRibbon {
    background-color: #e9b2b7;
    margin-bottom: 0.75rem;
  }
}
