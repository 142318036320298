@import "./colors";

// Prime default overrides

.p-toast {
  margin-top: -10px;
}

.p-component {
  font-family:
    "Maersk Text",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

// .p-dialog .p-dialog-footer {
//   // background: #f3f3f3;
//   padding: 1rem;
// }

// .p-dialog .p-dialog-content {
//   padding-bottom: 0;
// }

.p-tabview .p-tabview-panels {
  padding: 1rem 0 0;
}

.p-tabview .p-tabview-title {
  font-weight: 400;
}

.p-dialog .p-card {
  border-style: solid;
  border-color: var(--gray-400);
  border-width: 0.5px;
}

// overriding card in main display card
.mainDisplayCard {
  .p-card {
    border-style: solid;
    border-color: var(--gray-400);
    border-width: 0.5px;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.p-editor-container .p-editor-toolbar {
  background: white;
}

.p-card {
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;

  .p-card {
    border-style: solid;
    border-color: var(--gray-400);
    border-width: 0.5px;
  }

  .editable {
    .p-card {
      border-style: solid;
      border-color: transparent;
      border-width: 0.5px;
      outline: 2px solid var(--primary-color);
    }
  }

  .deleted {
    .p-card {
      border-style: solid;
      border-color: transparent;
      border-width: 0.5px;
      outline: 2px solid red;

      div:not(.p-button) {
        opacity: 0.8;
      }
    }
  }

  .added {
    .p-card {
      border-style: solid;
      border-color: transparent;
      border-width: 0.5px;
      outline: 2px solid green;
    }
  }

  .p-card-title {
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }

  //.p-card-body {
  //  padding: 2.5rem 2rem;
  //}

  .p-card-content {
    padding: 0;
  }
}

.editable {
  .p-card {
    border-style: solid;
    border-color: transparent;
    border-width: 0.5px;
    outline: 2px solid var(--primary-color);
  }
}

.deleted {
  .p-card {
    border-style: solid;
    border-color: transparent;
    border-width: 0.5px;
    outline: 2px solid red;

    div:not(.p-button) {
      opacity: 0.8;
    }
  }
}

.added {
  .p-card {
    border-style: solid;
    border-color: transparent;
    border-width: 0.5px;
    outline: 2px solid green;
  }
}

.p-fieldset .p-fieldset-content {
  padding: 0;
}

.p-breadcrumb {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0.5rem 0;
}

.p-divider.p-divider-horizontal::before {
  border-top: 1px var(--text-color-secondary);
  border-top-style: dashed;
}

.p-tag {
  height: fit-content;
}

.p-tag.p-tag-info {
  font-weight: lighter;
  color: var(--text-color);
  background-color: whitesmoke;
}

// .p-datatable .p-datatable-thead > tr > th {
//   background-color: var(--blue-900);
//   color: var(--gray-50);
// }

// .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
//   padding: 1.25rem 0.5rem;
// }

// tooltip
.general-tooltip.p-tooltip {
  .p-tooltip-text {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    background-color: #fff;
    color: #545454;
    line-height: 1.5rem;

    //box-shadow: none;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 4%), 0 1px 18px rgb(0 0 0 / 3%), 0 6px 10px rgb(0 0 0 / 3%);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-left: 5px;
  }

  .p-tooltip-arrow {
    border-right-color: lightgray;
    margin-left: 5px;
    vertical-align: top;
  }
}

.general-tooltip.custom-tooltip {
  max-width: 258px;
  max-height: 116px;
}

//.tooltip-no-shadow.p-tooltip {
//  .p-tooltip-text {
//    box-shadow: none;
//  }
//}

// spinner color
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #42b0d5;
  }

  100% {
    stroke: #42b0d5;
  }
}

// Dropdown

.p-inputtext {
  font-family:
    "Maersk Text",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
}

// small dropdown
.dropdown-small {
  .p-dropdown .p-dropdown-label {
    height: 1.5rem;
  }

  .p-inputtext {
    padding: 0 0.3rem;
    font-size: small;
  }
}

.changed {
  .p-inputtext {
    color: var(--primary-color);
    font-weight: bold;
  }
}

// input

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.input-small {
  .p-inputtext {
    padding: 0.3rem;
    font-size: small;
    width: 80%;
  }
}

h2 {
  .p-inputtext {
    font-family:
      "Maersk Headline",
      -apple-system,
      BlinkMacSystemFont,
      "Microsoft JhengHei",
      "Microsoft Yahei",
      "微软雅黑",
      STXihei,
      "华文细黑",
      sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-size: x-large;
    font-weight: 400;
    width: 50rem;
  }
}

.title {
  margin: 2rem 0;

  .p-inputtext {
    font-family:
      "Maersk Headline",
      -apple-system,
      BlinkMacSystemFont,
      "Microsoft JhengHei",
      "Microsoft Yahei",
      "微软雅黑",
      STXihei,
      "华文细黑",
      sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-size: xxx-large;
    font-weight: 200;
    display: inline-block;
    max-width: 50rem;
    min-width: 40rem;
  }
}

.titleH2 {
  margin: 2rem 0;

  .p-inputtext {
    font-family:
      "Maersk Headline",
      -apple-system,
      BlinkMacSystemFont,
      "Microsoft JhengHei",
      "Microsoft Yahei",
      "微软雅黑",
      STXihei,
      "华文细黑",
      sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-size: xx-large;
    font-weight: 200;
    display: inline-block;
    max-width: 50rem;
    min-width: 40rem;
  }
}

// pop-up
.p-confirm-popup {
  box-shadow: none;
  border: 0.5px solid var(--gray-400);
}

// radio buttons
.p-radiobutton {
  margin-right: 3px;
  vertical-align: baseline;
}

// Editor

// invalid editor content
.invalid {
  outline: 1px solid red;
}

.ql-editor > * {
  font-family:
    "Maersk Text",
    -apple-system,
    BlinkMacSystemFont,
    "Microsoft JhengHei",
    "Microsoft Yahei",
    "微软雅黑",
    STXihei,
    "华文细黑",
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

.isChanged {
  // .ql-editor > * {
  //   color: var(--primary-color);
  //   font-weight: bold;
  // }
}

// sidebar

.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-sidebar-right .p-sidebar {
  width: auto;
}

// dialog
.p-dialog .p-dialog-footer button {
  margin: 0 0 0 1rem;
}

.p-dialog .p-dialog-footer {
  padding: 1rem 1.5rem;
  background: #f7f7f7;
}

.partial-selection {
  outline: 1.5px solid #b5e0f5;
}

//Autocomplete
.p-autocomplete {
  // border: 1px solid var(--gray-400);
  // border-radius: 4px;
  width: 100%;

  .p-autocomplete-multiple-container .p-autocomplete-token {
    margin-bottom: 2px;
    border-radius: 15px;
  }

  .p-inputtext {
    width: 100%;
  }

  // .p-inputtext {
  //   border: 0;

  //   &:enabled:focus {
  //     box-shadow: 0 0 0 0;

  //     //border-color: none;
  //   }
  // }

  // .p-autocomplete-panel {
  //   width: 100%;
  // }

  .p-button.p-button-icon-only {
    // background: white;
    // border: 0;

    // .pi {
    //   color: grey;
    // }
  }
}

// CheckBox
.smallCheckbox {
  .p-checkbox .p-checkbox-box {
    height: 1rem;
    width: 1rem;
    margin: auto;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 0.75rem;
  }
}

// InputSwitch
.p-inputswitch {
  width: 2.25rem;
  height: 1.25rem;

  .p-inputswitch-slider::before {
    width: 1rem;
    height: 1rem;
    left: 0.2rem;
    margin-top: -0.525rem;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  transform: translateX(0.8rem);
}
