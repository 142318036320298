.pickListContainer {
  width: 76.87rem;
  height: 40.12rem;
  display: flex;
  justify-content: center;

  .columnContent {
    width: 33.75rem;

    .searchInput {
      width: 33.75rem;
      height: 2.5rem;
      border-radius: 0;
    }

    .searchIcon {
      margin-left: 0.4rem;
    }

    .clearSearch {
      cursor: pointer;
      font-size: small;
      margin-top: -0.4rem;
      margin-right: 0.4rem;
    }

    .columnContentBody {
      border: 1px solid #ced4da;
      border-top: 0;
    }

    .allItemsControl {
      font-size: small;
      color: var(--primary-color);
      padding: 1rem 0;

      span {
        cursor: pointer;
        margin-right: 1rem;
      }
    }

    .pickListItems {
      padding: 0 1rem;
      height: 32rem;
      cursor: pointer;
      overflow-y: scroll;

      .lineItems {
        margin: 2px 0;
        border-radius: 4px;
      }

      .lineItems:hover {
        background: #e9ecef;

        // outline: 1px solid var(--primary-color);
      }
    }

    .itemSelected {
      background: #e9f6fc !important;
    }
  }

  .arrowControls {
    width: 5.625rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    button {
      width: 3rem;
    }
  }
}
