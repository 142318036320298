.filterPanel {
  padding: 2%;
  display: block;
  font-size: 12px;

  .div_inline {
    margin-right: 5%;
    display: inline-block;
    vertical-align: top;

    p {
      padding-bottom: 2%;
    }
  }

  .controlOption {
    div {
      display: inline-flex;
    }
  }
}

.margin_controls {
  margin-right: 20px !important;
}
