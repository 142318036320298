.headerView {
  
  background-color: var(--gray-100);
  border-radius: 0.25rem;
  width: 21.25rem;

  .headeritems {
    padding: 1rem 1.653rem 3.125rem 1.653rem;

    .type {
      margin: 0rem 0rem 0.313rem 0rem;
      font-weight: 700;
    }

    .pencilIcon {
      margin-left: 0.7rem;
      cursor: pointer;
      font-size: 0.813rem;
      color: #0073ab;
    }

    .trashIcon {
      margin-left: 0.7rem;
      cursor: pointer;
      font-size: 0.813rem;
      color: #0073ab;
    }

    .pencilIconFont{
      font-size: 0.86rem;
    }

    .headerWidth {
      width: 100%;
    }

    .symbol {
      display: flex;
    }

    .notDefined {
      font-size: 0.875rem;
      color: #b2b2b2;
    }

    .tag {
      margin-right: 0.75rem;
      margin-bottom: 0.75rem;
      color: #141414;
      font-weight: 400;
      font-size: 0.75rem;
    }

    .vertical {
      background-color: #b5e0f5;
    }

    .customerType {
      background-color: #ededed;
    }

    .loadType {
      background-color: #ffeba8;
    }

    .direction {
      background-color: #e9b2b7;
    }

    .attractiveness {
      background-color: #ffeba8;
    }

    .carrier {
      background-color: #b5e0f5;
    }

    .incoterm {
      background-color: #e9b2b7;
    }

    .packagingType {
      background-color: #ffeba8;
    }

    .transportedByMaersk {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #0073ab;
    }

    .icons {
      display: flex;
      justify-content: space-between;

      .headerIcon{
        width: 2rem;
      }
    }
  }
}

.headerBorder {
  border: 0.094rem solid #ededed;
}

.headerContainer {
  margin: 0.438rem 0rem 1.75rem 0rem;
}

.alignCenter{
  text-align: center;
}