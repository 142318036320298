.componentAccordion {

	.p-accordion .p-accordion-tab {
		margin-bottom: 1rem;
		// margin-left: 1.5rem;
		border: none;
	}

	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link,
	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link:hover {
		background-color: #ffffff;
		box-shadow: none;
	}

	.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link,
	.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link:hover,
	.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
		// border-top: 1px solid #dee2e6;
	}

	.p-accordion .featureAccordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link,
	.p-accordion .featureAccordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link:hover,
	.p-accordion .featureAccordion .p-accordion-tab:nth-child(n) .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
		border-top: none;
	}

	.p-accordion-header-text {
		width: 100%;
	}

	.p-accordion .p-accordion-content {
    background-color: #ffffff !important;
	}

}