.ComponentReorder {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .draggableBox {
    border-radius: 4px;
    outline: 1px solid #cfcfcf;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: inherit;
  }

  .grabCursor {
    cursor: grab;
  }

  .grabbingCursor {
    cursor: grabbing;
  }
}
