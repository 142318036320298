.SolutionAddComponentFromProductsDialog {
  .componentsView {
    margin-top: "1%";

    .header {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 2.12rem;
      }

      .leftAlignedButtons {
        margin-left: auto;
      }

      button {
        margin-left: 0.5rem;
      }
    }
  }
}
