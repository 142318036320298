.ShowMultiTreeTypeDimension {
  .regionTreeContent {
    margin-left: -10px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;

    .alignIcon {
      align-self: flex-start;
      margin-top: 3px;
    }
  }

  .banIcon {
    color: #b80012;
  }

  .checkIcon {
    color: #40ab35;
  }
}
