.createSolutionDialog {
  background-color: #0ca0ce;
  width: 40rem;

  .sectionBlock {
    margin: 0.5rem 0;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 5rem;

    .inputGroup {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      
      .label {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        white-space: nowrap;
        min-width: 8rem;

        .optionLabel {
          color: red;
        }
      }

      input,
      textarea,
      .dropdown {
        width: fill-available;
      }

      .valueField {
        width: fill-available;
      }

      textarea {
        min-width: 50rem;
        min-height: 8rem;
      }

      .helpText {
        height: 2rem;
        width: 100%;
      }
    }
  }
}
