.ghostElement {
  .p-card:first-child {
    border: none;
    outline: 0.15rem dashed;
  }

  .sortableItem:first-child {
    border: none;
    outline: 0.15rem dashed;
  }
}
