.SpecificationParameterConfiguration{

}

.configurableAt {
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  display: grid;
  padding: 1rem;
  grid-template-columns: 12rem minmax(0, 1fr);

  .contractingTitle {
    color: #000;
    font-weight: 600;
    font-size: 16px;
  }

  .configurations {
    & > div {
      display: inline-block;
      vertical-align: top;
    }

    .configuration {
      width: 50%;
      line-height: 1.5rem;

      .subConfiguration {
        margin-left: 1rem;
      }
    }
  }
}

.notAuthorized {
  display: flex;
}

.configurationOption {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
