.FeatureAsServiceEdit {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-top: 0.5rem;
  gap: 1rem;
  .InputText {
    color: #545454;
  }

  .InputActionButtons {
    display: flex;
    justify-content: flex-end;

    button {
      width: 1.7rem !important;
      height: 1.7rem !important;
      margin-top: 0 !important;
      margin-left: 0.5rem !important;
    }
  }

  .featureTypeInnerRadioGroupRadioButtons {
    display: flex;
    gap: 2.5rem;

    .featureTypeRadioButton {
      display: flex;
      gap: 0.5rem;

      .featureTypeRadioButtonLabel {
        color: #141414;
      }
    }
  }

  .featureInnerRadioGroup {
    display: flex;
    flex-direction: column;
    padding: 0rem 0rem 0rem 2rem;
    gap: 0.75rem;

    .defaultMsg {
      gap: 1rem;

      .warningMsg {
        color: #b80012;
        font-size: 0.9rem;
        display: flex;
        padding-top: 0.2rem;
      }

      .secondLineMsg {
        color: #545454;
        font-size: 0.9rem;
      }
    }

    .featureTypeInnerRadioGroupRadioButtons {
      display: flex;
      gap: 2.5rem;

      .featureTypeRadioButton {
        display: flex;
        gap: 0.5rem;

        .featureTypeRadioButtonLabel {
          color: #141414;
        }
      }
    }
  }
}

.featureType {
  display: flex;
  gap: 0.5px;
  align-items: center;

  .featureTypeLabel {
    padding-left: 0.5rem;
  }
}

.element {
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 100%;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.element:hover .tooltip {
  display: flex;
  width: 20rem;
  color: #545454;
  font-size: 0.85rem;
  background-color: white;
}