.featureDetailPanel {
  .featureHeaderSection {
    .featureHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .closeIcon {
        font-size: large;
        cursor: pointer;
      }
    }

    .featureVersionServiceType {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      .actionButtons {
        display: flex;
        gap: 0.5rem;
      }

      .serviceType {
        margin-left: auto;

        .serviceDefaultAndDollar {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .alignLast {
            align-self: flex-end;
          }

          .labelBox {
            padding: 2px 10px;
            border-radius: 100px;

            .label {
              padding: 2px 10px;
            }
          }

          .labelBoxCore {
            background: #b5e0f5;
          }

          .labelBoxOptional {
            background: #ffeba8;
          }

          .labelBoxNonService {
            background: #ededed;
          }
        }
      }

      .arrowUp {
        cursor: pointer;
      }

      .arrowUp:hover {
        color: #0073AB;
      }
    }
  }

  .seperator {
    border-color: #3333;
  }

  .section {
    // padding: 0.5rem 1.5rem;
  }

  .featureDescriptionSection {
    .featureDescriptionLabel {
      font-size: larger;
    }
  }
}
