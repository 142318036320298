.ApiError {
  .cardStyle {
    margin: 1% 4%;

    .header {
      font-size: xx-large;
      margin: 2.5rem 0;
    }

    .content {
      margin-top: 2.5rem;

      .container {
        display: table;

        .row {
          display: table-row;
        }

        .column {
          display: table-cell;
          min-width: 10rem;
        }
      }

      .errorParams {
        margin: 1rem 0;

        .key {
          font-weight: bold;
          margin-right: 1rem;
        }
      }
    }
  }
}
