.SpecificationList {
  .divider {
    width: 100%;
  }

  .specificationContent {
    padding: 1rem;
    background-color: #f9fcff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;

    .specificationList {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      .header {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;

        .headerAndButton {
          display: flex;
          align-items: center;
          width: 100%;

          h4 {
            margin: 0;
          }

          .actionButtons {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}
