.SolutionFeatureAssociations {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature_group_indentation {
  margin-left: 2rem;
}

.feature_group_margin {
  margin-bottom: 2.813rem;
}

.feature_group_name {
  font-weight: 400;
  font-size: 1.625rem;
  margin-bottom: 1rem;
  margin-top: 2.813rem;
  display: flex;
  justify-content: space-between;

  .featureNameVersion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .featureVersion {
      font-size: 0.875rem;
      line-height: 1.063rem;
      padding-left: 0.5rem;
    }

    .featureVersionAvailable {
      background-color: #ffeba8;
      border-radius: 10% / 50%;
      font-size: 0.875rem;
      padding: 2px 10px;
    }

    .previewBtn {
      background-color: #fff;
      color: #141414;
      border: 1px solid #cfcfcf;
    }
  }
}
