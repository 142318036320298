.UserListForRoleV2 {
  background-color: var(--gray-100);
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;

  .title {
    width: 20%;
  }

  .yetToBeAssigned {
    font-size: smaller;
    font-style: italic;
    color: var(--gray-800);
    width: 60%;
  }

  .userInfo {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user {
      // padding: 0.5rem;

      // margin-bottom: 1rem;
      font-size: 0.75rem;
      line-height: 1rem;

      .userName {
        color: var(--blue-800);
      }

      .mail {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }

    .statusImg {
      .approvedColor {
        color: #40ab35;
      }

      .rejectedColor {
        color: #c6280f;
      }
    }
  }

  .editSection {
    margin-left: auto;
    font-size: smaller;
    color: var(--blue-800);
    cursor: pointer;
  }

  .editIcon {
    margin-left: auto;
    cursor: pointer;
  }

  .userList {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .highlightMe {
      background: #ededed;
      border-radius: 4px;
    }
  }

  .showAll {
    text-align: center;
    font-size: smaller;
    color: var(--primary-color);
    cursor: pointer;
  }
}

.footer {
  display: flex;
  font-size: small;
  color: #b2b2b2;

  i {
    margin-right: 0.5rem;
  }
}
