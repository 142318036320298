.SolutionComponentAssociation {
  .header {
    display: flex;
    align-items: center;

    .leftAlignedButtons {
      margin-left: auto;
    }

    button {
      margin-left: 0.5rem;
    }
  }
}
