.ViewOrEditSpecConfig {
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	padding: 1rem;

	.configurationTitle{
		font-size: medium;
		font-weight: 600;
		color: #000;
	}

	.configurableAt{
		margin-top: 10px;
		display: grid;
		grid-template-columns: 12rem minmax(0, 1fr);
	
		.configurations {
			& > div {
				display: inline-block;
				vertical-align: top;
			}
	
			.configuration {
				width: 50%;
				line-height: 1.5rem;
	
				.subConfiguration {
					margin-left: 1rem;
				}
			}
		}
	}
	

	
}