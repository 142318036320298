.associationDetailsComponent {

	.productCard{
		border:1rem solid #F7F7F7;
		margin: 1.5rem 0rem;

		.productNameSection {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F7F7F7;

			.productNameAndUpgrade {
				display: flex;
				align-items: flex-end;
				gap: 1rem;

				.productName{
					font-size: x-large;
					font-weight: bold;
					padding-top: 1rem;
				}

				.featureVersionAvailable {
					background-color: #ffeba8;
					border-radius: 10% / 50%;
					padding: 0.25rem 0.5rem;
					font-size: small;
					font-weight: normal;
				}

				.productUpgrade{
					font-weight: normal;
					cursor: pointer;
					color: #1c80cf;
					text-decoration: underline;
					margin-left: 0.5rem;
				}
			}

			.deleteProductIcon{
				cursor: pointer;
			}

			.relationType{
				width: 22.5rem;
			}
		
			.relationTypeViewTag {
				display: flex;
				align-items: center;
				gap: 0.5rem;
		
				.relationTypeViewText {
					font-size: 0.875rem;
					font-weight: normal;
					padding: 0.25rem 0.625rem;
					border-radius: 10% / 50%;
				}
		 
				.relationTypeViewTextCore {
					background-color: #b5e0f5;
				}
		
				.relationTypeViewTextOptional {
					background-color: #ffeba8;
				}
			}

			.selectProductType {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
      }

			.deleteProductIcon{
				cursor: pointer;
				background-color: #FFFFFF;
        border : 1px solid #ced4da
			}

		}
	}

	.componentName {
		font-size:	larger;
		padding: 1.5rem 0;
		background-color: #F7F7F7;
	}

	.componentSection{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #F7F7F7;

		.componentName {
			font-size:	larger;
			padding: 1.5rem 0;
			background-color: #F7F7F7;
		}

		.componentActionBtn{
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.5rem;
			
			.addFeatureBtn {

			}

			.removeComponentBtn{

			}
		}
	}

	

	.componentCard{
		border:1rem solid #F7F7F7;
		margin-bottom: 1.5rem;
	}

	.featureList{
		display: flex;
    flex-direction: column;
    gap: 1rem;
	}

}

.customDropdownItem {
  max-width: 18.75rem;
 
  .optionName {
    color : #141414;
		font-size: 1rem;
  }
 
  .optionDescription {
    color: #767676;
		font-size: 0.75rem;
  }
}
 
.customDropdownItem span {
  display: block;
}
 
.customDropdownItem div {
  white-space: normal; 
}