.SpecificationList {
  .divider {
    width: 100%;
  }

  .specificationContent {
    padding: 1rem;
    background-color: #f9fcff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;

    .specificationList {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      .header {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;

        .headerAndButton {
          display: flex;
          align-items: center;
          width: 100%;

          h4 {
            margin: 0;
          }

          .actionButtons {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }

      .specification {
        .specificationHeader {
          display: flex;
          margin-bottom: 1rem;

          .specNameAndIcon {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          h4 {
            margin: 0;
          }

          .actionButtons {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .badgeStyle {

            .spTypeInnerRadioGroupRadioButtons {
              display: flex;
              gap: 2.5rem;
            
              .spTypeRadioButton {
                display: flex;
                gap: 0.5rem;
            
                .spTypeRadioButtonLabel {
                  color: #141414;
                }
              }
            }

            .tagName {
              height: 27px;
              vertical-align: middle;
              padding: 5px 10px 0px 10px;
              color: #141414;
              font-weight: 400;
            }
          
            .coreServiceTag {
              background-color: #B5E0F5;
            }
          
            .optionalServiceTag {
              background-color: #FFEBA8;
            }
          
            .noneServiceTag {
              background-color: #EDEDED;
            }
          
            
          }
        }

        .specificationInnerContent {
          display: grid;
          grid-template-columns: 1fr 10rem;
          column-gap: 1rem;

          .specificationDescriptionAndConfig {
            .specificationConfiguration {
              margin-top: 2.5rem;
            }
          }

          .configurable {
            // background-color: var(--gray-100);
            border-radius: 4px;
            border: 1px solid #dbdbdb;
            padding: 1rem;
            display: flex;
            flex-direction: column;

            .checkBoxOption {
              display: flex;
              gap: 0.5rem;
              align-items: center;
            }

            .configurableHeader {
              margin-bottom: 0.5rem;
            }

            .configurableOptions {
              margin-left: 1rem;
              font-size: smaller;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
