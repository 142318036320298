.productView {
  .productHeader {
    text-align: left;
    margin: 1.5rem 0;
    position: relative;

    .headerRow {
      display: flex;
      flex-wrap: wrap;

      .productVersion {
        color: var(--text-color-secondary);
        margin-top: 0.5rem;

        .versionSelect {
          border: none;
          margin-bottom: 1rem;
          background: #f9fcff;
        }
      }

      .headerSection {
        display: flex;
        align-items: center;
        gap: 1rem;

        .nonCompatibleIcon {
          fill: #B80012;
        }
      }

      .addonType {
        height: fit-content;
        background-color: #ffde74;
        border-radius: 10rem;
        font-size: 0.875rem;
        padding: 0.125rem 0.625rem;
        white-space: nowrap;
      }

      .actionButtons {
        margin-left: auto;
        align-self: center;
        display: flex;

        button {
          margin: 0.5rem 0.5rem 0.5rem 0;
        }
      }

      .editButton {
        margin-left: auto;
        align-self: center;
        display: flex;

        button {
          margin: 0.5rem 0.5rem 0.5rem 0;
        }

        .approvalButton {
          background-color: #00243d;
          color: #fff;
        }
      }

      @media screen and (max-width: 1210px) {
        .actionButtons { margin-left: 0; }
      }
    }
  }

  .ribbonBar {
    display: flex;
    justify-content: space-between;
    background-color: #ffeba8;
    border-radius: 0.25rem;
    margin-bottom: 0.75rem;

    .ribbonInfo {
      display: flex;
      align-items: center;

      .ribbonInfoIcon {
        padding: 1rem 1.25rem;
      }

      .link {
        text-decoration: underline;
        color: #00243d;
        cursor: pointer;
      }
    }

    .ribbonCloseIcon {
      padding: 1.4rem 1.25rem;
      cursor: pointer;
    }
  }

  .productDraftVersionRibbon {
    background-color: #b5e0f5;
    margin-bottom: 0.75rem;
  }

  .productNonCompatibilityRibbon {
    background-color: #FCDDE0;
    margin-bottom: 0.75rem;
  }

  .productDraftRejectionRibbon {
    background-color: #e9b2b7;
    margin-bottom: 0.75rem;
  }

  .mainCardPosition {
    position: relative;

    .featureUpgradeBadge {
      position: relative;

      a::after {
        content: "";
        position: absolute;
        top: 9px;
        right: 7px;
        height: 12px;
        width: 12px;
        min-width: auto;
        background-color: #ffc319;
        border-radius: 50%;
      }
    }
  }

  .delete_button {
    // button{
    color: red;
    border: 1px solid red;

    // }
  }

  .productName {
    text-align: left;
    margin: 1.5rem 0;
  }

  .productNameExtras {
    text-align: left;
    margin-left: 0.5rem;
    vertical-align: baseline;
  }

  .productVersion {
    color: var(--text-color-secondary);
    margin-top: 0.5rem;
  }

  .productVersionTags {
    //font-size: smaller;
  }

  .productNameTag {
    margin-left: 0.25rem;
    vertical-align: middle;
  }

  .featuresByComponent {
    .componentGroup {
      margin-bottom: 2rem;
    }

    .feature {
      margin-bottom: 1rem;
    }
  }

  .productComponentTab {
    .component {
      .content {
        position: relative;
        text-align: left;
        padding: 30px;

        .container {
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;

          div {
            span {
              display: inline-block;
            }

            min-width: 25%;
            padding: 20px;
          }
        }
      }
    }

    .featureComponent {
      padding-top: 60px;
      text-align: left;

      .header {
        padding-bottom: 30px;
        font-size: x-large;
      }
    }
  }
}

.dialogBody {
  .dialogLabel {
    margin-bottom: 0.75rem;
  }
}

.goToProductUpArrowCursorPointer {
  cursor: pointer;
}