.productAccordion {
  .p-accordion .p-accordion-content {
		border: none;
    border-radius: 4px;
    background-color: #f7f7f7;
	}

	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link,
	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link:hover {
		border: none;
		box-shadow: none;
		padding: 0rem;
	}

	.p-accordion-header-text {
		width: 100%;
	}

  .p-accordion-header-link {
    border: none !important;
    background-color: #F7F7F7;
    border-radius: 4px;
    box-shadow: none;
    display: flex;
    align-items: baseline;
  }

.ProductAccordion_productAccordion__4w9Vo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid #DBDBDB;
  margin-bottom: 1rem !important;
}

.p-accordion-tab {
  border: 1px solid #DBDBDB;
  border-radius: 4px;
}

.p-accordion-content {
  background-color: #f7f7f7 !important;
}

}
