.ProductCardMedium {
  border: 0.5px solid var(--gray-400);
  border-radius: 4px;
  padding: 1rem;
  width: 22rem;
  height: 14.5rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: black;
  }

  .cardContent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title {
      font-size: larger;
    }

    .description {
      font-size: small;
      color: var(--gray-600);
    }

    .productFamily {
      font-size: smaller;
      color: var(--gray-600);

      svg {
        font-size: 1.25em;
        color: var(--primary-color);
        vertical-align: text-bottom;
        margin-right: 0.25rem;
      }
    }

    .cardFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: smaller;
      margin-top: auto;

      .editSection {
        margin-left: auto;
        color: var(--blue-800);
        cursor: pointer;
        text-decoration: underline;
      }

      .addonType {
        flex-shrink: 0;
        background-color: #ffde74;
        border-radius: 10rem;
        padding: 0.125rem 0.625rem;
      }

      .productVersionAndDeleteIcon {
        margin-left: auto;

        .deleteIcon {
          margin-left: 0.5rem;
        }
      }
    }

    .familyAndNonCompatibleIcon {
      display: flex;
      justify-content: space-between;

      .nonCompatibleIcon {
        fill: #B80012;
      }
    }
  }

  .statusLabel {
    position: absolute;
    background-color: #ffde74;
    padding: 0 2.5rem 0 2rem;
    font-size: x-small;
    float: inline-end;
    transform: rotate(46deg);
    right: -2.25rem;
    line-height: 1rem;
  }

  .retiredStatusLabel {
    background-color: #b80012;
    color: #fff;
  }
}

.upgradeCard {
  background-color: #fff1c2;
}

.retiredCard {
  background-color: #f7f7f7;
}

.nonCompatibleCard {
  background-color: #E9B2B766;
  ;
}
