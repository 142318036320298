.headerBody {
  .linkSection {
    margin: 0.5rem 0rem 0.5rem 0rem;
    display: flex;
    gap: 1rem;
    padding: 0rem 0rem 1rem 0rem;
    color: var(--primary-color);

    span {
      cursor: pointer;
      margin-right: 0.5rem;
    }

  }

  .optionsSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .optionLabel {
      margin-left: 0.75rem;
    }
  }
}
