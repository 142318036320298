.CreateOrEditSpecificationParameter {
  // width: 50rem;
  background: #f9fcff;
  border: 1px solid var(--gray-400);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  padding: 2rem;
  border-radius: 4px;

  .defaultSwitch {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .defaultInfo {
      font-size: smaller;
      color: #545454;
    }
  }

  .specificationAssociatedInfo {
    padding: 0.5rem;
    border-radius: 4px;
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: #ffde74;
  }

  .disablePointerEvents {
    pointer-events: none;
  }


  h2 {
    margin: 0;
  }

  .groupCheckBoxAndDropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .addToGroupDropDown {
      width: 18rem;
    }
  }

  .formElement {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .specificationSection {
    .selected {
      border: 2px solid #0073ab !important;
    }

    .specificationTypeIcons {
      display: flex;
      margin-top: 1rem;
      flex-direction: row;
      gap: 1rem;
    }

    .specificationTypeElement {
      margin-top: 1.75rem;

      .textSpecificationElementInput {
        width: 100%;
      }

      .rangeFields {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .rangeField {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .rangeTextField {
            width: 4rem;
          }

          .rangeDropDown {
            width: 7rem;
            align-items: center;
            height: 33px;
          }
        }
      }

      .optionsForm {
        margin-top: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .optionElement {
          display: flex;
          gap: 0.75rem;

          .optionData {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .nameAndDefaultSwitch {
              display: flex;
              gap: 1.5rem;

              .nameTextBox {
                width: 40rem;
              }
            }

            .optionsDescription {
              width: 100%;
            }
          }

          .deleteButton {
            margin-left: auto;
          }
        }

        // .optionElement {
        //   display: flex;
        //   gap: 0.75rem;

        //   .name {
        //     width: 30%;
        //   }

        //   .description {
        //     width: 70%;
        //   }

        //   .deleteButton {
        //     margin-left: auto;
        //   }

        //   .optionsValueFieldWidth {
        //     width: 100%;
        //   }

        //   .smWidth {
        //     width: 100%;
        //   }
        // }
      }

      .specTypeImageDiv {
        img {
          width: "24";
          height: "24";
        }
      }
    }

    .specificationTypeBox {
      width: 8rem;
      border: 1px solid #cfcfcf;
      border-radius: 4px;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: #878787;
      cursor: pointer;
      background: white;
    }
  }

  .configurableSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .configurations {
      display: flex;
      gap: 2rem;

      .subConfigurations {
        margin-left: 1rem;
      }

      .configurableOption {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 0.25rem;
      }
    }

    .configurationSubTitle {
      padding: 0px;
      margin: 0px;
      color: #545454;
      font-size: 14px;
    }
  }

  .alignButtonsInLine {
    button {
      margin-right: 0.5rem;
    }
  }
}

.featureHeader {
  display: flex;
  align-items: center;

  .alignButtonsInLine {
    margin-left: auto;

    button {
      margin-left: 0.5rem;
    }
  }
}

.featuresCard {
  padding: 2rem;
  width: 50rem;
  background: #f7f7f7;
}

.componentForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.featureTypeInnerRadioGroupRadioButtons {
  display: flex;
  gap: 2.5rem;
  margin-top: 12px;

  .featureTypeRadioButton {
    display: flex;
    gap: 0.5rem;

    .featureTypeRadioButtonLabel {
      color: #141414;
      font-size: 14px;
    }
  }
}

.configurableAt {
  padding: 24px 0px 24px 24px;
  width: 71%;
  background: rgba(181, 224, 245, 0.25);
  margin: 24px 0px 24px 0px;

  .checkboxAlignments {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;

    .contractingTitle {
      color: #000000;
      font-weight: 600;
      font-size: 16px;
    }
  }


  .configurableOption {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.25rem;

    .mandatoryCheckbox {
      margin-left: 64%;
      margin-top: 0px;
      padding-top: 0px;
      width: 19px;
      height: 14px;
      margin-bottom: 6px;

      .mandatoryText {
        margin: 0px;
        padding: 0px;
        font-size: 12px;
      }
    }

  }

  .configurableOption1 {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.25rem;
    flex-direction: column;

    .mandatoryCheckbox {
      margin-left: 0%;
      width: 17px;
      margin-bottom: 2px;
      margin-right: 5px;
    }

    .mandatoryText {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
    }
  }

}