.FeatureSpecificationComponent {
  .bullet {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .defaultSwitch {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .defaultInfo {
      font-size: smaller;
      color: #545454;
    }
  }

  .canMultiselect {
    margin-bottom: 2rem;
  }

  .specValueItem {
    padding-bottom: 1rem;

    .specValueNameAndDefault {
      display: flex;
      align-items: center;
      gap: 1.25rem;
    }
  }

  .specValueDescription {
    font-size: smaller;
    padding-left: 1.75rem;
    padding-top: 0.5rem;
  }

  .isChanged {
    color: var(--primary-color);
    font-weight: bold;
  }

  .isMissing {
    color: var(--gray-600);
    font-style: italic;
  }

  .Check,
  .Cross {
    margin-right: 0.7rem;
  }

  .label {
    padding: 0.25rem 0.6rem;
    border-radius: 10rem;
    font-size: smaller;
  }

  .grayLabel {
    background: #ededed !important;
  }
}

.dollarLabelBox {
  background: #ededed;
  border-radius: 50%;
  display: flex;
}

.flexDisplay {
  display: flex;
  align-items: center;
}
