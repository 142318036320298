.addSolutionComponentDialog {
	background-color: #0ca0ce;
  width: 36rem;
	
	.sectionBlock {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .inputGroup {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;;

      .label {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        white-space: nowrap;
        min-width: 8rem;
      }

      .type {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .typeBox {
          display: flex;
          flex-direction: column;
          flex : 1;
          gap: 0.25rem;
          align-items: center;
          border: 1px solid #CFCFCF;
          border-radius: 0.25rem;
          padding: 1.25rem;
          text-align: center;
          cursor: pointer;

          .typeDesc {
            font-size: 0.90rem;
            color: #545454;
          }
        }

        .type_box:hover {
          border-color: var(--primary-color);
        }

        .selected {
          border: 2px solid var(--primary-color);
        }
      }

      input,
      textarea,
      .dropdown {
        width: fill-available;
      }

      .valueField {
        width: fill-available;
      }


      textarea {
        min-width: 50rem;
        min-height: 8rem;
      }

      .helpText {
        height: 2rem;
        width: 100%;
      }
    }
  }
}