.CommercialInfo {

	.attributes {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.attribute {

			.attributeHeader {
				display: flex;
				align-items: center;
				gap : 1rem;

				.attributeIcon {
					color: var(--primary-color);
          vertical-align: middle;
          padding-right: 1rem;
          font-size: 2rem;
				}

				.editAttribute {
					cursor: pointer;
				}
			}

			.attributeText {
				margin-left: 4rem;
			}
		}
	}
}