.SolutionProductAssociationNew {

	.sharedComponent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .blockComponent {
      display: flex;
      gap: 0.6rem;
      justify-content: space-between;
      border: 0.5px solid var(--gray-400);
      min-height: 3.5rem;
      align-items: center;
      padding: 0 1.25rem;
      border-radius: 0.25rem;

      .productList {
        display: flex;

        .products {
          color: #0073ab;
          font-size: 0.9rem;
          padding-left: 0.5rem;
        }
      }
    }
  }

  .noSharedComponent {
    margin: 0 0 1rem 1rem;
  }

	.productsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .productCountDropdown {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .buttonSection {
      display: flex;
      gap: 0.5rem;
    }
  }


	.actionSection{
		display: flex;
		align-items: center;
		gap: 2rem;
		margin-bottom: 1rem;
		cursor: pointer;
		color: #0073AB;

    .actionIconLabel {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .underline{
      text-decoration: underline;
    }
	}
}