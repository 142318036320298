.featureAccordion {

	.p-accordion .p-accordion-content {
		border: none;
		padding: 0rem;
	}

	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link,
	.p-accordion .p-accordion-tab:nth-child(n) .p-accordion-header .p-accordion-header-link:hover {
		background-color: #ffffff;
		border: none;
		box-shadow: none;
		padding: 0.5rem;
	}

	.p-accordion-header-text {
		width: 100%;
	}
	
}