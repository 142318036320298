.ProductSelectionDialog {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .search {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    div {
      flex-grow: 1;

      input {
        width: 100%;
      }
    }

    span {
      width: 100%;
    }
  }

  .bulkSelectionActions {
    span {
      margin-right: 0.5rem;
      font-size: smaller;
      text-decoration: underline;
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  .productsView {
    // margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .header {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 2rem;
      }

      .leftAlignedButtons {
        margin-left: auto;
      }

      button {
        margin-left: 0.5rem;
      }
    }
  }
}
