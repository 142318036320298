.CriterionComponent {
  .criteriaGrid {
    display: grid;
    grid-template-columns: 20rem 10rem 1fr 5rem;
    column-gap: 0.5rem;

    .gridItemDimensionLabel {
      grid-column: 1/3;
    }

    .gridItemValueLabel {
      grid-column: 3/5;
    }

    .gridItemIncludeLabel {
      justify-self: center;
      align-self: center;
    }

    .gridItemCriteriaValueValidation {
      grid-column: 3/4;
    }

    .criteriaHeader {
      display: flex;
      align-items: center;

      .dimensionKey {
        flex: 0 0 17.5rem;
      }
    }

    .criteria {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .criteriaValue {
      overflow: hidden;
    }
  }
}
