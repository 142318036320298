.ScopeCard2 {
  min-height: 36rem;
  border: 1px solid #ededed;
  background: rgb(181 224 245 / 10%);
  border-radius: 4px;
  padding: 1.25rem 1.25rem 2rem;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 1rem;

    .scopeTitle {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      min-width: 43.25rem;
    }

    .scopeTitleNonEdit {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
    }

    .scopeCount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: var(--primary-color);
      border: 0.5px solid var(--primary-color);
      border-radius: 4px;
      background: white;
      width: 2.5rem;
      height: 2.5rem;
    }

    .editField {
      align-self: center;
      cursor: pointer;;
    }

    .deleteButton {
      margin-left: auto;
    }
  }

  .scopeContent {
    display: flex;
    gap: 1rem;

    .dimensionSublistSelectionAndValues {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .includedExcludedValuesArea {
        display: flex;
        gap: 1rem;
        height: 100%;
      }
    }

    .dimensions {
      width: 22.5rem;

      .dimensionTile {
        background-color: white;
        height: 5rem;
        border: 0.5px solid var(--primary-color);
        border-radius: 4px;
        padding: 0.75rem 1.5rem 0.75rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        .dimensionIconName {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .dimensionTitle {
          display: flex;
          color: var(--primary-color);
          justify-content: flex-start;

          .dimensionValues {
            margin-top: 0.3rem;
            margin-left: 0.4rem;

            .dimensionName {
              margin-left: 0.75rem;
            }

            .dimensionInclusion {
              font-size: 0.875rem;
              font-weight: lighter;
              color: #545454;
            }
          }
        }

        .dimensionDeleteEditIcon {
          display: flex;
          gap: 1rem;
          margin-left: auto;
          justify-content: space-evenly;
          align-items: center;
          color: #353535;

          .deleteIconDisabled {
            opacity: 0.4;
          }

          .iconColor {
            color: #353535 !important
          }
        }
      }

      .addDimensionButton {
        height: 5rem;
        margin-top: 2rem;
        text-align: center;

        .addDimensionWrapper {
          background: transparent;
          border: none;

          &:focus {
            box-shadow: none;
          }
        }

        .addButtonActive {
          background: white;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .defaultIconColor {
        svg {
          color: white;

          path {
            fill: #2196f3 !important;
          }
        }
      }

      .clickedDimension {
        background: var(--primary-color);

        .dimensionTitle {
          color: white;
        }

        .editIcon {
          color: white;
        }

        svg {
          color: white;

          path {
            fill: white !important;
          }
        }

        .dimensionValues {
          span {
            color: white;
          }
        }
      }
    }

    .showSelection {
      border: 1px solid var(--gray-300);
      border-radius: 4px;
      padding: 1.25rem 1.87rem;
      background: white;
      width: 23.5rem;

      .selectionHeader {
        padding-bottom: 0.5rem;
        height: 2.5rem;
        display: flex;
        gap: 0.5rem;

        .inclusionCircle {
          border-radius: 2.25rem;
          width: 1.43rem;
          height: 1.6rem;
          background: #c5e5c2;
          text-align: center;
          padding: 0.125rem 0.5rem;
          font-weight: 400;
          font-size: 0.75rem;
        }

        .exclusionCircle {
          border-radius: 2.25rem;
          width: 1.43rem;
          height: 1.6rem;
          background: #e9b2b7;
          text-align: center;
          padding: 0.125rem 0.5rem;
          font-weight: 400;
          font-size: 0.75rem;
        }
      }

      .wrapperDiv {
        height: calc(100% - 2.5rem);
        overflow-y: scroll;
      }

      h5 {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }

}

.banIcon {
  color: #b80012;
}

.checkIcon {
  color: #40ab35;
}

.scopeTypeIcon {
  margin-right: 0.5rem;
}

.invertIcon {
  transform: scaleX(-1);
}

.rejectBtn {
  background: #FFFFFF;
  color: #141414;
  border: 1px solid #CFCFCF;
}

.acceptBtn {
  background: #00243D;
  color: var(--primary-color-text);
  border: none;
}

.deleteDimension {
  margin: 2rem 1rem 0 1rem;

  .deleteDimensionHeader {
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2rem;
  }

  .deleteDimensionSubHeader {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .deleteDimensionBody {
    margin: 1rem 0;
  }

  .deleteDimensionBodyContent {
    background: #F7F7F7;
    border: 1px solid #EDEDED;
    border-radius: 0.25rem;
    line-height: 1.5rem;

    .warningList {
      margin: 1rem;

      .warningItem {
        padding: 0.5rem;
        display: flex;
        align-items: baseline;
        gap: 0.25rem;

        i {
          margin-right: 0.25rem;
          color: #E0280F;
          font-size: large;
        }
      }

    }
  }
}
