.HomeViewComponent {
  .welcome {
    min-height: 24rem;
    background: #e9f6fc;

    .user {
      padding-left: 3rem;
    }
  }

  .homeViewContent {
    margin-top: -15rem;
    padding: 0rem 5rem 3rem 3rem;
  }
}
