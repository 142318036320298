.dialogSize {
  width: 90vw;
  height: 80vh;
  overflow-y: hidden;

  .dialogContent {
    margin-top: 1.5rem;
  }
}

.dialogFooter {
  margin-top: 1.5rem;
}
