.SolutionOverview {
  display: grid;
  column-gap: 1.25rem;
  grid-template-columns: minmax(0, 1fr) 21.25rem;

  .formFieldList {
    .field {
      margin-bottom: 1rem;
      min-height: 5rem;

      .fieldTitle {
        display: flex;
        align-items: center;
        gap: 1rem;

        .editField {
          cursor: pointer;
        }
      }

      .keyContactsUserLists {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;
        width: 100%;

        // .overlayForUsers {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   background-color: rgb(0 0 0 / 50%);
        //   z-index: 2;
        //   border-radius: 4px;

        .redRibbon {
          // margin: 9rem 2rem;
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: #e9b2b7;
          border-radius: 4px;

          .icon {
            margin: 0 0.5rem;
          }

          .link {
            // color: var(--primary-color);
            text-decoration: underline;

            // margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        .yellowRibbon {
          // margin: 9rem 2rem;
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: #ffeba8;
          border-radius: 4px;

          .icon {
            margin: 0 0.5rem;
          }

          .link {
            // color: var(--primary-color);
            text-decoration: underline;

            // margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        // }
      }

      .fieldTextValue {
        margin-left: 1.5rem;
      }
    }
  }
}

.spin {
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
