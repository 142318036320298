.ViewOrAddUsersDialog {

}

.user {
  .email {
    color: var(--primary-color);
    font-size: smaller;
    text-decoration: underline;
  }
}

.dialogContent {
  padding-top: 1rem;
  // width: 35rem;
  height: 45rem;

  .searchBarAndButton {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .existingUsers {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .userInfoWrapper {
      padding: 0.75rem;
      background-color: #F7F7F7;

      .userInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user {
          border-radius: 0.25rem;
          display: flex;
          align-items: center;

          .displayName{
            color: #003D6D;
          }
        }

        .statusImg {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;

          .deleteIcon {
            margin-left: auto;
            color: var(--blue-600);
            cursor: pointer;
          }
          
          .statusIcon{
            padding-top: 0.25rem;

            .approvedColor {
              color : #40AB35
            }

            .rejectedColor {
              color : #C6280F
            }
          }
        }
        
      }

      .userComment {
        padding-top: 0.5rem;
        color : #545454;
      }
    }

    .userInfoDefaultApprover {
      background-color: #EDEDED;
    }
  }
}
