.OverviewForm {
  .formFieldList {
    .description {
      display: inline-block;
      font-size: medium;
      text-align: left;
      width: 100%;
    }

    .field {
      margin-bottom: 1rem;
      min-height: 5rem;

      .fieldTitle {
        .modConStatus {
          background-color: #ededed;
          padding: 0.25rem 1rem;
          font-size: smaller;
          border-radius: 10rem;
        }

        .modConStatusActive {
          background-color: #c5e5c2;
        }

        i {
          color: var(--primary-color);
          vertical-align: middle;
          padding-right: 1rem;
          font-size: 2rem;
        }
      }

      .fieldTextValue {
        margin-left: 3rem;
      }

      .fieldRadioButton {
        margin-left: 3rem;

        span {
          margin-right: 0.5rem;
        }
      }

      .keyContactsUserLists {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;
        width: 100%;

        // .overlayForUsers {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   background-color: rgb(0 0 0 / 50%);
        //   z-index: 2;
        //   border-radius: 4px;

        .redRibbon {
          // margin: 9rem 2rem;
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: #e9b2b7;
          border-radius: 4px;

          .icon {
            margin: 0 0.5rem;
          }

          .link {
            // color: var(--primary-color);
            text-decoration: underline;

            // margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        .yellowRibbon {
          // margin: 9rem 2rem;
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: #ffeba8;
          border-radius: 4px;

          .icon {
            margin: 0 0.5rem;
          }

          .link {
            // color: var(--primary-color);
            text-decoration: underline;

            // margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        // }
      }
    }

  }

  .editFooter {
    padding-top: 1rem;
  }

  .editor {
    margin: 1rem 0;
  }

  .displayDescription {
    white-space: pre-line;
    margin: 1rem 0;
  }

  .inputField {
    width: 100%;
  }
}

.spin {
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
